import html2canvas from "html2canvas";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../components/css/style2.css";
import backgroundImage from "../components/img/background.jpg";
import backgroundImage2 from "../components/img/bg1.png";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

const ImageCrp = () => {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [imgSrc, setImgSrc] = useState("");
  const [aspect, setAspect] = useState(1);
  const [canvasDataUrl, setCanvasDataUrl] = useState(null);
  const combinedCanvasRef = useRef(null);
  const [fullName, setFullName] = useState("");
  const [key, setKey] = useState(Math.random());
  const [data, setData] = useState({
    total: 0,
    today: 0,
    mobile: 0,
    desktop: 0,
  });

  const [currentTextIndex, setCurrentTextIndex] = useState(0); // To track the current text index

  const [textPoints, setTextPoints] = useState([
    "Humble & Grounded",
    "A Reservoir of Knowledge",
    "Voice for Professionals",
    "Visionary Leader",
    "Responsive to Feedback",
    "Committed to Ethical Practices",
  ]);

  // useEffect(() => {
  //   const storedIndex = localStorage.getItem("currentTextIndex");
  //   if (storedIndex) {
  //     setCurrentTextIndex(parseInt(storedIndex, 10));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("currentTextIndex", currentTextIndex);
  // }, [currentTextIndex]);

  useEffect(() => {
    const storedIndex = localStorage.getItem("currentTextIndex");
    if (storedIndex) {
      setCurrentTextIndex(parseInt(storedIndex, 10));
    } else {
      const randomIndex = Math.floor(Math.random() * textPoints.length);
      setCurrentTextIndex(randomIndex);
    }
  }, [textPoints]);

  useEffect(() => {
    localStorage.setItem("currentTextIndex", currentTextIndex);
  }, [currentTextIndex]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://pk24.nextgenidea.in/api/getCounter`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        console.log(data.data.count.total);

        setData({
          desktop: data.data.count.desktop || 0,
          mobile: data.data.count.mobile || 0,
          today: data.data.count.today || 0,
          total: data.data.count.total || 0,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [key]);

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const canvas = previewCanvasRef.current;
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

        canvas.width = Math.floor(completedCrop.width * scaleX);
        canvas.height = Math.floor(completedCrop.height * scaleY);

        canvasPreview(imgRef.current, canvas, completedCrop, scale, rotate);
        setCanvasDataUrl(canvas.toDataURL());
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  useEffect(() => {
    if (canvasDataUrl && combinedCanvasRef.current) {
      const combinedCanvas = combinedCanvasRef.current;
      const context = combinedCanvas.getContext("2d");

      const background = new Image();
      background.src = backgroundImage;
      background.onload = () => {
        combinedCanvas.width = background.width;
        combinedCanvas.height = background.height;
        context.drawImage(background, 0, 0);

        const uploadedImage = new Image();
        uploadedImage.src = canvasDataUrl;
        uploadedImage.onload = () => {
          const centerX = 3240 + 1500; // Adjust 1480 and 425 as needed
          const centerY = 435 + 950; // Adjust 550 and 425 as needed
          const radius = 1050; // Adjust as needed

          context.save();
          context.beginPath();
          context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
          context.clip();
          context.drawImage(uploadedImage, 3500, 345, 2300, 2300);
          context.restore();

          const textX = 100 + 100;
          const textY = 100 + 100; // Adjust the distance from the bottom of the image
          context.fillStyle = "white";
          context.textAlign = "center";
          context.fillText(fullName, textX, textY);
        };
      };
    }
  }, [canvasDataUrl, fullName]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const generateImage = async () => {
    try {
      if (!fullName) {
        alert("Please enter your name!");
        return;
      }

      if (!imgSrc) {
        alert("Please select your image!");
        return;
      }

      const na = document.getElementById("name");
      const im = document.getElementById("image");
      im.style.display = "block";
      na.style.display = "none";

      setCurrentTextIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % textPoints.length;
        return nextIndex;
      });

      const randomIndex = Math.floor(Math.random() * textPoints.length);
      setCurrentTextIndex(randomIndex);

      // switch (currentTextIndex) {
      //   case 0:
      //     setCurrentTextIndex(1);
      //     break;
      //   case 1:
      //     setCurrentTextIndex(2);
      //     break;
      //   case 2:
      //     setCurrentTextIndex(3);
      //     break;
      //   case 3:
      //     setCurrentTextIndex(4);
      //     break;
      //   case 4:
      //     setCurrentTextIndex(5);
      //     break;
      //   default:
      //     setCurrentTextIndex(0);
      //     break;
      // }

      const response = await fetch(
        `https://pk24.nextgenidea.in/api/addVisitor`,
        {
          method: "POST",
          body: JSON.stringify({
            name: fullName,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textPoints.length);

      // console.log(data);
    } catch (err) {
      alert(err);
    }
  };

  const generateNewImage = () => {
    const na = document.getElementById("name");
    const im = document.getElementById("image");
    im.style.display = "none";
    na.style.display = "block";
    setFullName("");
    setImgSrc("");
    setCompletedCrop("");
    setKey(Math.random());
  };

  const download = () => {
    const scaleFactor = 2; // Increase this for higher resolution
    html2canvas(document.getElementById("img"), { scale: scaleFactor }).then(
      function (canvas) {
        const image = canvas.toDataURL("image/png", 1.0);
        const link = document.createElement("a");
        link.download = "PK24";
        link.href = image;
        link.click();
      }
    );
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <div
      style={{
        //backgroundImage: `url(${backgroundImage2})`,
        height: "100%",
        width: "100%",
        marginTop: "10%",
        //marginBottom: "10%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div>
        <div id="name">
          <div
            className="col-12"
            style={{
              display: "flex",
              // border: " 2px solid black",
              width: "70%",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "-3%",
              marginTop: "15%",
              borderRadius: "40px",
              backgroundColor: "#cde3d6ba",
              // backgroundImage: `url(${backgroundImage2})`,
            }}
          >
            <div>
              <div>
                {/* <h1 style={{ color: "dark blue", marginTop: "10%" }}>
                  Generate I Support CA. Pinky Kedia
                </h1> */}
              </div>
              <div
                style={{
                  width: "80%",
                  maxWidth: "600px",
                  margin: "0 auto",
                  padding: "20px",
                  // backgroundColor: "#cde3d6ba",
                  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

                  borderRadius: "10px",
                }}
              >
                <form action="/ImageCropper" key={key}>
                  <div style={{ marginBottom: "10px" }}>
                    <label
                      htmlFor="fname"
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        display: "block",
                        fontSize: "16px",
                        // marginLeft: "-10px",
                        // fontWeight: "bold",
                        // display: "block",
                      }}
                    >
                      Enter Your Name:
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      onChange={(e) => setFullName(e.target.value)}
                      style={{
                        // margin: "8px",
                        // width: "100%",
                        // padding: "8px",
                        // borderRadius: "5px",
                        // border: "1px solid #ccc",
                        marginTop: "8px",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #000",
                        fontSize: "14px",
                        boxSizing: "border-box",
                      }}
                      maxLength={23}
                    />

                    <br />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <label
                      htmlFor=""
                      style={{
                        // fontWeight: "bold",
                        // marginBottom: "5px",
                        // display: "block",
                        fontWeight: "bold",
                        display: "block",
                        fontSize: "16px",
                        marginLeft: "5px",
                      }}
                    >
                      Upload Your Photo:
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFile}
                      style={{
                        // width: "100%",
                        // padding: "8px",
                        // borderRadius: "5px",
                        // border: "1px solid #ccc",
                        marginTop: "8px",
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #000",
                        fontSize: "14px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                </form>
              </div>
              <div
                className="counter-section"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="counter-card"
                  style={{
                    border: "2px solid #ccc",
                    padding: "20px",
                    margin: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                    width: "150px",
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <h5>Desktop</h5>
                  <p>{data.desktop}</p>
                </div>
                <div
                  className="counter-card"
                  style={{
                    border: "2px solid #ccc",
                    padding: "20px",
                    margin: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                    width: "150px",
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <h5>Mobile</h5>
                  <p>{data.mobile}</p>
                </div>
                <div
                  className="counter-card"
                  style={{
                    border: "2px solid #ccc",
                    padding: "20px",
                    margin: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                    width: "150px",
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <h5>Today Total</h5>
                  <p>{data.today}</p>
                </div>

                <div
                  className="counter-card"
                  style={{
                    border: "2px solid #ccc",
                    padding: "20px",
                    margin: "10px",
                    borderRadius: "10px",
                    marginBottom: "20px",
                    width: "150px",
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <h5>Total</h5>
                  <p>{data.total}</p>
                </div>
              </div>

              <div>
                {!!imgSrc && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    circularCrop
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                        width: "500px",
                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary"
              onClick={generateImage}
              style={{ margin: "15px" }}
            >
              Create
            </button>
          </div>
        </div>

        <div
          id="image"
          className=" container-fluid  main"
          style={{
            display: "none",
          }}
        >
          <div className="row">
            <div
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
              style={{ marginTop: "2%" }}
            >
              <div
                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
                id="img"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  height: "650px",
                  width: "650px",
                  backgroundSize: "cover",
                  marginTop: "15%",
                  margin: "auto",
                  backgroundPosition: "center",

                  objectFit: "cover",
                }}
              >
                {!!completedCrop && (
                  <>
                    <div style={{ display: "flex" }}>
                      <canvas
                        id="canvas"
                        ref={previewCanvasRef}
                        style={{
                          borderRadius: "50%",
                          marginLeft: "66px ",
                          marginTop: "149px",
                          width: "177px",
                          height: "177px",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "-420px",
                          fontSize: "18px",
                          color: "white",
                          fontWeight: 700,
                          marginTop: "54.5%",
                        }}
                      >
                        {fullName}
                      </div>

                      <div
                        style={{
                          width: "100%",
                          marginLeft: "-606px",
                          fontSize: "25px",
                          color: "#e89b27",
                          fontWeight: 500,
                          marginTop: "68.5%",
                        }}
                      >
                        {textPoints[currentTextIndex]}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <button
              className="btn btn-success"
              onClick={download}
              style={{ margin: "20px" }}
            >
              Download
            </button>

            <button
              className="btn btn-primary"
              onClick={generateNewImage}
              style={{ margin: "20px" }}
            >
              New
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCrp;
